import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Web3 from "web3";
import "./App.css";

import Navbar from "./components/Header";
import "react-tooltip/dist/react-tooltip.css";
import NetworkChange from "./networkSwitch";
import Farming from "./pages/Farming";
import Staking from "./pages/Staking";
import NftMint from "./pages/NftMint";
import Bridge from "./pages/Bridge";
import { NETWORK_CHAIN_ID, NETWORK_RPC_URL } from "./connectivity/Environment";

const supportedChainIds = [1, 42161];

const web3 = new Web3(
  Web3.givenProvider ? Web3.givenProvider : NETWORK_RPC_URL
);

function App() {
  const [switchNetwork, setSwitchNetwork] = useState(false);

  let chain = async () => {
    try {
      const chainId = await web3.eth.getChainId();
      const {pathname} = this.props.location;
        console.log("pathname:", pathname);
      if (pathname === "bridge") {
        if (!supportedChainIds.includes(chainId)) {
          setSwitchNetwork(true);
        }
      } else if (+chainId !== NETWORK_CHAIN_ID) {
        setSwitchNetwork(true);
      }

    } catch (error) {}
  };

  useEffect(() => {
    chain();
  }, []);

  return (
    <div>
      <BrowserRouter>
        <NetworkChange open={switchNetwork} setOpen={setSwitchNetwork} />
        <Navbar />
        <Routes>
          <Route path="/" element={<Staking />} />
          <Route path="/bridge" element={<Bridge />} />
          <Route path="/moonbags" element={<NftMint />} />
          <Route path="/staking" element={<Staking />} />
          <Route path="/farming" element={<Farming />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
