import { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { MaxUint256 } from "@ethersproject/constants";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { ethers } from "ethers";
import moment from "moment/moment";

import { Tooltip } from "react-tooltip";

import { FlexDataBox } from "../components/FlexDataBox";
import AntTab from "../components/AntTab";
import TabPanel from "../components/TabPanel";
import { AppContext } from "../utils";
import { useStakingContract, useTokenContract } from "../connectivity/Hooks";
import { STAKING_CONTRACT_ADDR, NETWORK_CHAIN_ID } from "../connectivity/Environment";

import stake1 from "../assets/stake1.png";
import stake2 from "../assets/stake2.png";
import Loading from "../loading";
import { ToastNotify } from "../components/Alert";

function Staking() {
  const { account, signer } = useContext(AppContext);
  const stakingContract = useStakingContract(signer);
  const tokenContract = useTokenContract(signer);

  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [balanceOfValue, setBalanceOfValue] = useState(0);
  // const [stakingCards, setStakingCards] = useState([]);
  const [userDataInfo, setUserDataInfo] = useState([]);
  const [amount, setAmount] = useState("");
  const [amount1, setAmount1] = useState("");
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const [poolValues, setPoolValues] = useState({
    firstPoolStakers: 0,
    firstPoolStaked: 0,
    firstPoolReward: 0,
    secondPoolStakers: 0,
    secondPoolStaked: 0,
    secondPoolReward: 0,
  });

  const [totalWithdrawn, setTotalWithdrawn] = useState();

  const init = useCallback(async () => {
    try {
      const decimals = await tokenContract.decimals();

      const dataFirstPool = await stakingContract.pools(0);
      const dataSecondPool = await stakingContract.pools(1);
      const _totalWithdrawn = await stakingContract.totalWithdrawn();
      setTotalWithdrawn(formatUnits(_totalWithdrawn, 18));

      if (account) {
        const balanceOf = await tokenContract.balanceOf(account);

        const { _stakeCount } = await stakingContract.getUserInfo(account);

        let dataArr = [];
        for (let i = 0; i < +_stakeCount; i++) {
          let {
            _planIndex,
            _Amount,
            _startTime,
            _endTime,
            _reward,
            _harvestTime,
            _isWithdrawn,
          } = await stakingContract.getUserStakeInfo(account, i);

          let { _reward: reward } = await stakingContract.calculateReward(
            account,
            i,
            Number(_planIndex)
          );

          let obj = {
            plan: +_planIndex,
            staked: +formatUnits(_Amount.toString(), +decimals),
            startTime: _startTime,
            endTime: +_endTime,
            reward: parseFloat(
              +formatUnits(reward.toString(), +decimals)
            ).toFixed(0),
            rewardHarvested: parseFloat(
              +formatUnits(_reward.toString(), +decimals)
            ).toFixed(0),
            harvestTime: _harvestTime,
            _isWithdrawn,
          };
          dataArr.push(obj);
        }
        setUserDataInfo(dataArr);
        setBalanceOfValue(+formatUnits(balanceOf.toString(), +decimals));
      }
      setPoolValues({
        firstPoolStakers: +dataFirstPool.totalStakers,
        firstPoolStaked: parseFloat(
          +formatUnits(dataFirstPool.poolCurrentStaked.toString(), +decimals)
        ).toFixed(0),
        firstPoolReward: parseFloat(
          +formatUnits(
            dataFirstPool.totalDistributedReward.toString(),
            +decimals
          )
        ).toFixed(0),
        secondPoolStakers: +dataSecondPool.totalStakers,
        secondPoolStaked: parseFloat(
          +formatUnits(dataSecondPool.poolCurrentStaked.toString(), +decimals)
        ).toFixed(0),
        secondPoolReward: parseFloat(
          +formatUnits(
            dataSecondPool.totalDistributedReward.toString(),
            +decimals
          )
        ).toFixed(0),
      });
    } catch (e) {
      if (e?.data?.message) {
        setAlertState({
          open: true,
          message: e?.data?.message,
          severity: "error",
        });
      } else if (e?.reason) {
        setAlertState({
          open: true,
          message: e?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: e?.message,
          severity: "error",
        });
      }
    }
  }, [account, stakingContract, tokenContract]);

  // stake handler
  const stakeHandler = async (index) => {
    if (!account) {
      return setAlertState({
        open: true,
        message: "Please connect your wallet.",
        severity: "error",
      });
    }
    try {
      setLoading(true);

      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexValue(NETWORK_CHAIN_ID) }],
      });

      let allowance = await tokenContract.allowance(
        account,
        STAKING_CONTRACT_ADDR
      );

      let amountToStake = amount
        ? parseUnits(amount.toString())
        : parseUnits(amount1.toString());
      let getAllowance = +formatUnits(allowance.toString());

      if (getAllowance < amountToStake) {
        let tx = await tokenContract.approve(
          STAKING_CONTRACT_ADDR,
          MaxUint256.toString()
        );
        await tx.wait();
      }

      let res = await stakingContract.stake(amountToStake, index, {
        gasLimit: "2200000",
      });

      await res.wait();
      init();
      setAmount("");
      setAmount1("");
      setLoading(false);
      setAlertState({
        open: true,
        message: "Transaction succeeded.",
        severity: "success",
      });
    } catch (e) {
      setLoading(false);
      if (e?.data?.message) {
        setAlertState({
          open: true,
          message: e?.data?.message,
          severity: "error",
        });
      } else if (e?.reason) {
        setAlertState({
          open: true,
          message: e?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: e?.message,
          severity: "error",
        });
      }
    }
  };

  // withdraw handler
  const withdrawHandler = async (index) => {
    try {
      setLoading(true);
      let res = await stakingContract.withdraw(index, {
        gasLimit: "2200000",
      });
      await res.wait();
      init();
      setLoading(false);
      setAlertState({
        open: true,
        message: "Transaction succeeded.",
        severity: "success",
      });
    } catch (e) {
      setLoading(false);
      if (e?.data?.message) {
        setAlertState({
          open: true,
          message: e?.data?.message,
          severity: "error",
        });
      } else if (e?.reason) {
        setAlertState({
          open: true,
          message: e?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: e?.message,
          severity: "error",
        });
      }
    }
  };

  // harvest handler
  const harvestHandler = async (index) => {
    try {
      setLoading(true);
      let res = await stakingContract.harvest(index, {
        gasLimit: "2200000",
      });
      await res.wait();
      init();
      setLoading(false);
      setAlertState({
        open: true,
        message: "Harvested successfully!",
        severity: "success",
      });
    } catch (e) {
      setLoading(false);
      if (e?.data?.message) {
        setAlertState({
          open: true,
          message: e?.data?.message,
          severity: "error",
        });
      } else if (e?.reason) {
        setAlertState({
          open: true,
          message: e?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: e?.message,
          severity: "error",
        });
      }
    }
  };

  useEffect(() => {
    init();
  }, [init]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  // input onChange handler
  const onChangeHandler = useCallback((e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (value.match(/^[0-9]*[.,]?[0-9]*$/)) {
      if (name === "base") {
        setAmount(value);
      }
    }
  }, []);
  const onChangeHandler1 = useCallback((e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (value.match(/^[0-9]*[.,]?[0-9]*$/)) {
      if (name === "base1") {
        setAmount1(value);
      }
    }
  }, []);

  return (
    <Container maxWidth="md">
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />

      <Box textAlign="center" py={5}>
        <Typography mb={5} variant="h2" color="#fff">
          Staking
        </Typography>

        <Box
          sx={{
            background: "#2a2a2a",
            borderRadius: "30px",
            width: "fit-content",
            m: "auto",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            <AntTab label="POOL" />
            <AntTab label="PERSONAL" />
          </Tabs>
        </Box>

        <Box
          sx={{
            background: "#2a2a2a",
            width: "fit-content",
            borderRadius: "15px",
            m: "auto",
          }}
        >
          <div
            style={{
              color: "white",
              margin: "15px",
              padding: "15px",
            }}
          >
            <div>
              Total stakers:{" "}
              {(
                parseFloat(poolValues.firstPoolStakers) +
                parseFloat(poolValues.secondPoolStakers)
              ).toLocaleString()}
            </div>
            <div>
              Total staked:{" "}
              {(
                parseFloat(poolValues.firstPoolStaked) +
                parseFloat(poolValues.secondPoolStaked)
              ).toLocaleString()}{" "}
              Arbinu <br />
              <span style={{ fontSize: "12px" }}>
                or{" "}
                {(
                  ( parseFloat(poolValues.firstPoolStaked) +
                    parseFloat(poolValues.secondPoolStaked)
                  ) /
                  8750000
                ) // total supply - burnt
                  .toFixed(1)}
                % of supply
              </span>
            </div>
          </div>
        </Box>

        {/* ------------Pool Tab section---------- */}
        <TabPanel value={value} index={0}>
          <Grid container spacing={5} mt={5}>
            <Grid item xs={12} md={6}>
              <Box
                className="shadow"
                sx={{
                  background: "#000",
                  // background: "rgba(0, 0, 0, 0.4)",
                  borderRadius: "30px",
                  px: 2.5,
                  py: 3.5,
                }}
              >
                <img src={stake1} alt="" width="100%" />

                <Typography
                  id="reward-pool-14"
                  variant="subtitle2"
                  color="#d1d1d1"
                  textAlign="left"
                >
                  Reward Pool: 15% of Stake Tax
                </Typography>
                <Tooltip
                  anchorId="reward-pool-14"
                  place="bottom"
                  content=<small>
                    14-day Staking Rewards are calculated based on the amount of
                    accumulated Arbinu from the 0.3% Stake Tax on each
                    transaction.
                  </small>
                />
                <FlexDataBox
                  text="Stakers"
                  value={parseFloat(
                    poolValues.firstPoolStakers
                  ).toLocaleString()}
                />
                <FlexDataBox
                  text="Staked Arbinu"
                  value={parseFloat(
                    poolValues.firstPoolStaked
                  ).toLocaleString()}
                />
                <FlexDataBox
                  text="Rewards claimed"
                  value={parseFloat(
                    poolValues.firstPoolReward
                  ).toLocaleString()}
                />

                <Box pt={1} borderTop="1px solid #272128">
                  <Stack
                    mb={0.5}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="subtitle2"
                      color="#d1d1d1"
                      textAlign="left"
                    >
                      Balance:
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="#d1d1d1"
                      textAlign="right"
                    >
                      {parseFloat(balanceOfValue).toFixed(2)}
                    </Typography>
                  </Stack>

                  <TextField
                    fullWidth
                    size="small"
                    name="base"
                    value={amount}
                    onChange={(e) => onChangeHandler(e)}
                    placeholder="Enter amount to stake"
                    // value={enterAmount}
                    // onChange={(e) => setEnterAmount(e.target.value)}
                    sx={{
                      border: "none",
                      background: "#41455A",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        border: "none",
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },

                      input: {
                        color: "#fff",
                        fontSize: "14px",
                        fontWeight: "600",
                      },
                    }}
                  />
                </Box>

                <Button
                  onClick={() => stakeHandler(0)}
                  sx={{
                    width: "100%",
                    paddingY: "5px",
                    fontSize: "20px",
                    color: "#000",
                    backgroundColor: "#fff",
                    border: "2px solid #5FBEEE",
                    cursor: "pointer",
                    textTransform: "capitalize",
                    borderRadius: "10px",
                    marginTop: "30px",
                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#2584CF",
                    },
                  }}
                >
                  stake
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                className="shadow"
                sx={{
                  // background: "rgba(0, 0, 0, 0.4)",
                  background: "#000",
                  borderRadius: "30px",
                  px: 2.5,
                  py: 3.5,
                }}
              >
                <img src={stake2} alt="" width="100%" />

                <Typography
                  id="reward-pool-30"
                  variant="subtitle2"
                  color="#d1d1d1"
                  textAlign="left"
                >
                  Reward Pool: 35% of Stake Tax
                </Typography>
                <Tooltip
                  anchorId="reward-pool-30"
                  place="bottom"
                  content=<small>
                    30-day Staking Rewards are calculated based on the amount of
                    accumulated Arbinu from the 0.7% Stake Tax on each
                    transaction.
                  </small>
                />
                <FlexDataBox
                  text="Stakers"
                  value={parseFloat(
                    poolValues.secondPoolStakers
                  ).toLocaleString()}
                />
                <FlexDataBox
                  text="Staked Arbinu"
                  value={parseFloat(
                    poolValues.secondPoolStaked
                  ).toLocaleString()}
                />
                <FlexDataBox
                  text="Rewards claimed"
                  value={parseFloat(
                    poolValues.secondPoolReward
                  ).toLocaleString()}
                />

                <Box pt={1} borderTop="1px solid #272128">
                  <Stack
                    mb={0.5}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="subtitle2"
                      color="#d1d1d1"
                      textAlign="left"
                    >
                      Balance:
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="#d1d1d1"
                      textAlign="right"
                    >
                      {parseFloat(balanceOfValue).toFixed(2)}
                    </Typography>
                  </Stack>

                  <TextField
                    fullWidth
                    size="small"
                    name="base1"
                    value={amount1}
                    onChange={(e) => onChangeHandler1(e)}
                    placeholder="Enter amount to Stake"
                    // value={enterAmount}
                    // onChange={(e) => setEnterAmount(e.target.value)}
                    sx={{
                      border: "none",
                      background: "#41455A",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        border: "none",
                        "& fieldset": {
                          border: "none",
                        },
                        "&:hover fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },

                      input: {
                        color: "#fff",
                        fontSize: "14px",
                        fontWeight: "600",
                      },
                    }}
                  />
                </Box>

                <Button
                  onClick={() => stakeHandler(1)}
                  sx={{
                    width: "100%",
                    color: "#000",
                    paddingY: "5px",
                    fontSize: "20px",
                    backgroundColor: "#fff",
                    border: "2px solid #5FBEEE",
                    cursor: "pointer",
                    textTransform: "capitalize",
                    borderRadius: "10px",
                    marginTop: "30px",
                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#2584CF",
                    },
                  }}
                >
                  stake
                </Button>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>

        {/* ------------Personal Tab section---------- */}
        <TabPanel value={value} index={1}>
          <Box mb={10} height="100%" width="100%">
            <TableContainer
              border="none"
              pt={2}
              pb={5}
              borderRadius="20px"
              color="#ffffff"
            >
              <Table
                aria-label="simple table"
                style={{
                  borderRadius: "16px",
                  marginTop: "50px",
                  background: "rgba(255, 255, 255, 0.2)",
                  minWidth: "600px",
                }}
                color="#ffffff"
              >
                <TableHead color="#ffffff">
                  <TableRow
                    style={{
                      color: "#ffffff",
                      border: "none",
                    }}
                  >
                    <TableCell
                      style={{
                        border: "none",
                        color: "#ffffff",
                      }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      //   align="center"
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "#ffffff",
                        border: "none",
                      }}
                    >
                      STAKED
                    </TableCell>
                    <TableCell
                      //   align="center"
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "#ffffff",
                        border: "none",
                      }}
                    >
                      REWARDS TOTAL
                    </TableCell>
                    <TableCell
                      //   align="center"
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "#ffffff",
                        border: "none",
                      }}
                    >
                      REWARDS HARVESTED
                    </TableCell>
                    <TableCell
                      //   align="center"
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "#ffffff",
                        border: "none",
                      }}
                    >
                      LOCK PERIOD
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "#ffffff",
                        border: "none",
                      }}
                    >
                      UNLOCK TIME
                    </TableCell>

                    <TableCell
                      colSpan={2}
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "#ffffff",
                        border: "none",
                        textAlign: "center",
                      }}
                    >
                      ACTIONS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userDataInfo.length > 0 ? (
                    userDataInfo.map(
                      (
                        {
                          plan,
                          staked,
                          startTime,
                          endTime,
                          reward,
                          rewardHarvested,
                          harvestTime,
                          _isWithdrawn,
                        },
                        i
                      ) => (
                        <TableRow key={i}>
                          <TableCell
                            // align="center"
                            style={{
                              border: "none",
                              color: "#ffffff",
                            }}
                          >
                            {i + 1}
                          </TableCell>
                          <TableCell
                            // align="center"
                            style={{
                              border: "none",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#ffffff",
                              }}
                            >
                              {staked}
                            </Typography>
                          </TableCell>
                          <TableCell
                            // align="center"
                            style={{
                              border: "none",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#ffffff",
                              }}
                            >
                              {reward}
                            </Typography>
                          </TableCell>
                          <TableCell
                            // align="center"
                            style={{
                              border: "none",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#ffffff",
                              }}
                            >
                              {rewardHarvested}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              border: "none",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#ffffff",
                              }}
                            >
                              {plan === 0 ? "14 days" : "30 days"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            // align="center"
                            style={{
                              border: "none",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#ffffff",
                              }}
                            >
                              {moment.unix(endTime).format("lll")}
                            </Typography>
                          </TableCell>
                          {!_isWithdrawn ? (
                            <>
                              <TableCell
                                // align="center"
                                style={{
                                  border: "none",
                                  textAlign: "right",
                                }}
                              >
                                <Button
                                  disabled={moment().unix() < endTime}
                                  sx={{
                                    width: "80%",
                                    color: "#000",
                                    backgroundColor: "#fff",
                                    border: "2px solid #5FBEEE",
                                    cursor: "pointer",
                                    textTransform: "capitalize",
                                    borderRadius: "16px",
                                    "&:hover": {
                                      backgroundColor: "#5EBCEBa1",
                                      color: "#fff",
                                    },
                                  }}
                                  onClick={() => withdrawHandler(i)}
                                >
                                  <span
                                    style={{
                                      padding: "1px 8px",
                                    }}
                                  >
                                    withdraw
                                  </span>
                                </Button>
                              </TableCell>

                              <TableCell
                                // align="center"
                                style={{
                                  border: "none",
                                  textAlign: "left",
                                }}
                              >
                                <Button
                                  disabled={
                                    moment().unix() < endTime ||
                                    parseInt(harvestTime) + 86400 >
                                      moment().unix()
                                  }
                                  sx={{
                                    width: "80%",
                                    color: "#fff",
                                    background: "#5EBCEB",
                                    cursor: "pointer",
                                    border: "2px solid #5FBEEE",
                                    textTransform: "capitalize",
                                    borderRadius: "16px",
                                    "&:hover": {
                                      background: "#5EBCEBa1",
                                      color: "#fff",
                                    },
                                  }}
                                  onClick={() => harvestHandler(i)}
                                >
                                  <span style={{ padding: "1px 8px" }}>
                                    harvest
                                  </span>
                                </Button>
                              </TableCell>
                            </>
                          ) : (
                            <TableCell
                              colSpan={2}
                              style={{
                                border: "none",
                                textAlign: "center",
                                color: "white",
                              }}
                            >
                              <span>WITHDRAWN</span>
                            </TableCell>
                          )}
                        </TableRow>
                      )
                    )
                  ) : (
                    <TableRow>
                      <TableCell
                        // align="center"
                        colSpan={7}
                        style={{
                          border: "none",
                        }}
                      >
                        <Box
                          py={10}
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontSize: "17px",
                              fontWeight: "400",
                              marginTop: "10px",
                              color: "#ffffff",
                            }}
                          >
                            You have not staked any Arbinu yet
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </TabPanel>
      </Box>
    </Container>
  );
}

export default Staking;
