import React from 'react';
import { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  Typography,
} from "@mui/material";

import { AppContext } from "../../utils";
import { useArbitrumContract, useNftContract } from "../../connectivity/Hooks";
import { formatUnits, parseEther, formatEther } from "@ethersproject/units";


//import mintImg from "../../assets/moonbags.jpg";
//import mintImg from "../../assets/Moonbag.png";
import mintImg from "../../assets/moonbags-bags.png";
import Loading from "../../loading";
import { ToastNotify } from "../../components/Alert";

function NftMintForm () {
  const [amount, setAmount] = useState(1);
  const [approved, setApproved] = useState(false);
  const [cost, setCost] = useState(0);
  const [mintCost, setMintCost] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [loading, setLoading] = useState(false);
  const [balanceOfValue, setBalanceOfValue] = useState(0);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const { account, signer } = useContext(AppContext);
  const arbitrumContract = useArbitrumContract(signer);
  const nftContract = useNftContract(signer);

  const init = useCallback(async () => {
    try {
      const decimals = await arbitrumContract.decimals();

      if (account) {
        const balanceOf = await arbitrumContract.balanceOf(account);
        setBalanceOfValue(+formatUnits(balanceOf.toString(), +decimals));
      }

      if(nftContract) {
        const _mintCost = formatEther(await nftContract.mintCost());
        const totalSupply = Number(await nftContract.totalSupply());
        setTotalSupply(totalSupply);
        setMintCost(_mintCost);
        let totalCost = amount - (amount / 9);
        setCost(amount*_mintCost);
      }

      if(arbitrumContract) {
        checkArbitrumContract();
      }
    } catch (e) {
      if (e?.data?.message) {
        setAlertState({
          open: true,
          message: e?.data?.message,
          severity: "error",
        });
      } else if (e?.reason) {
        setAlertState({
          open: true,
          message: e?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: e?.message,
          severity: "error",
        });
      }
    }
  }, [account, arbitrumContract, nftContract]);


  React.useEffect(() => {
    const interval = setInterval(() => {
      updateSupply();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    init();
  }, [init, approved, account]);
  
  useEffect(() => {
    checkArbitrumContract();
  }, [amount]);

  const checkArbitrumContract = async () => {
    const decimals = await arbitrumContract.decimals();
    let approvedAmount = formatUnits(await arbitrumContract.allowance(account, nftContract.address), +decimals);
    console.log("approvedAmount: ", approvedAmount);
    console.log("cost: ", cost);
    if(approvedAmount > cost) {
      console.log("hello");
      setApproved(true);
    }
  }

  const updateSupply = async () => {
    const totalSupply = Number(await nftContract.totalSupply());
    setTotalSupply(totalSupply);
  }


  const approvalHandler = async () => {
    try {
      setLoading(true);

      let mintCostParsed = parseEther((mintCost * 100).toString())
      console.log("mintCost: ", mintCostParsed);
      let receipt = await arbitrumContract.approve(nftContract.address, mintCostParsed);
      await receipt.wait();
      console.log(receipt);

      init();
      setLoading(false);
      setAlertState({
        open: true,
        message: "Approval Success!!!\ntxHash: " + receipt.hash,
        severity: "success",
      });
    } catch (e) {
      setLoading(false);
      console.log(e);
      if (e?.data?.message) {
        setAlertState({
          open: true,
          message: e?.data?.message,
          severity: "error",
        });
      } else if (e?.reason) {
        setAlertState({
          open: true,
          message: e?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: e?.message,
          severity: "error",
        });
      }
    }
  };

  const mintHandler = async () => {
    try {
      setLoading(true);

      console.log("Amount: ", amount);
      console.log("Cost: ", cost);
      let saleCost = amount * cost;
      console.log("saleCost: ", saleCost);
      let receipt = await nftContract.safeMint(account, amount)
      await receipt.wait();
      console.log(receipt);

      init();
      setLoading(false);
      setAlertState({
        open: true,
        message: "Mint Success!!!\ntxHash: " + receipt.hash,
        severity: "success",
      });
    } catch (e) {
      setLoading(false);
      console.log(e);
      if (e?.data?.message) {
        setAlertState({
          open: true,
          message: e?.data?.message,
          severity: "error",
        });
      } else if (e?.reason) {
        setAlertState({
          open: true,
          message: e?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: e?.message,
          severity: "error",
        });
      }
    }
  };

  const minusHandler = useCallback((e) => {
    const newAmount = amount - 1;
    if (newAmount < 1) return;
    if (newAmount >= 9) {
      setAmount(8);
      setCost(8*mintCost);
    } else {
      setAmount(newAmount);
      setCost(newAmount*mintCost);
    }
  }, [amount, mintCost]);

  const plusHandler = useCallback((e) => {
    const newAmount = amount + 1;
    if (newAmount > 9) return;
    setAmount(newAmount);
    if (newAmount == 9) setAmount(10);
    setCost(newAmount*mintCost);
  }, [amount, mintCost]);



  return (
    <div className="MintForm">
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />

      <Grid container mt={5} mx="auto">
        <Box
          className="shadow"
          sx={{
            background: "#000",
            borderRadius: "30px",
            px: 2.5,
            py: 3.5,
            transition: "0.5s",
            width: { xs: "95%", md: "65%" },
            m: "auto"
          }}
        >
          <img src={mintImg} alt="" width="100%" />

          <Box pt={1} borderTop="1px solid #272128">

            <Stack
              mb={0.5}
              direction="row"
              justifyContent="space-around"

            >
              <Typography mt={4} variant="h2" color="#fff">
                { totalSupply } / 500
              </Typography>
            </Stack>

            <Stack
              mb={0.5}
              direction="row"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle2"
                color="#d1d1d1"
                textAlign="left"
              >
                Balance:
              </Typography>

              <Typography
                variant="subtitle2"
                color="#d1d1d1"
                textAlign="right"
              >
                {parseFloat(balanceOfValue).toFixed(0)} ARB
              </Typography>
            </Stack>

            <Stack
              mb={0.5}
              direction="row"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle2"
                color="#d1d1d1"
                textAlign="left"
              >
                Mint Price:
              </Typography>

              <Typography
                variant="subtitle2"
                color="#d1d1d1"
                textAlign="right"
              >

                {parseFloat(mintCost).toFixed(0)} ARB
              </Typography>
            </Stack>

            <Stack
              mb={0.5}
              direction="row"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle2"
                color="#d1d1d1"
                textAlign="left"
              >
                Total Cost:
              </Typography>
              <Typography
                variant="subtitle2"
                color="#d1d1d1"
                textAlign="right"
              >
                { cost == mintCost * 9 &&
                  <span style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid', color: 'red'}}>
                    { parseFloat(mintCost * 10).toFixed(0) + " " }
                  </span>
                }
                { cost == mintCost * 9 &&
                  <span style={{color: 'green'}}>
                    {parseFloat(cost).toFixed(0)} ARB
                  </span>
                }
                { cost != mintCost * 9 &&
                  <span>
                    {parseFloat(cost).toFixed(0)} ARB
                  </span>
                }
              </Typography>
            </Stack>

            <Stack
              mb={0.5}
              direction="row"
              justifyContent="space-between"
            >
              <Button
                onClick={() => minusHandler()}
                sx={{
                  width: "30%",
                  paddingY: "5px",
                  fontSize: "20px",
                  color: "#000",
                  backgroundColor: "#fff",
                  border: "2px solid #5FBEEE",
                  cursor: "pointer",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                  marginTop: "30px",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "#2584CF",
                  },
                }}
              >
                -
              </Button>

              <Typography mt={4} variant="h2" color="#fff">
                { amount < 10 &&
                    amount
                }
                { amount == 10 &&
                    (amount - 1).toString() + " + 1 FREE!"
                }
              </Typography>

              <Button
                onClick={() => plusHandler()}
                sx={{
                  width: "30%",
                  paddingY: "5px",
                  fontSize: "20px",
                  color: "#000",
                  backgroundColor: "#fff",
                  border: "2px solid #5FBEEE",
                  cursor: "pointer",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                  marginTop: "30px",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "#2584CF",
                  },
                }}
              >
                +
              </Button>
            </Stack>
          </Box>

            <Button
              onClick={() => approvalHandler()}
              sx={{
                width: "100%",
                paddingY: "5px",
                fontSize: "20px",
                color: "#000",
                backgroundColor: "#fff",
                border: "2px solid #5FBEEE",
                cursor: "pointer",
                textTransform: "capitalize",
                borderRadius: "10px",
                marginTop: "30px",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#2584CF",
                },
              }}
            >
              Approve
            </Button>

            <Button
              onClick={() => mintHandler()}
              sx={{
                width: "100%",
                paddingY: "5px",
                fontSize: "20px",
                color: "#000",
                backgroundColor: "#fff",
                border: "2px solid #5FBEEE",
                cursor: "pointer",
                textTransform: "capitalize",
                borderRadius: "10px",
                marginTop: "30px",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#2584CF",
                },
              }}
            >
              Mint
            </Button>
        </Box>
      </Grid>
    </div>
  )
}

export default NftMintForm;
