export const STAKING_CONTRACT_ADDR = process.env.REACT_APP_STAKING_CONTRACT_ADDR;
export const ARBINU_TOKEN_ADDR = process.env.REACT_APP_ARBINU_TOKEN_ADDR;
export const ARBINU_NFT_ADDR = process.env.REACT_APP_ARBINU_NFT_ADDR;
export const ARBITRUM_ADDR = process.env.REACT_APP_ARBITRUM_ADDR;
export const LP_STAKING_CONTRACT_ADDR = process.env.REACT_APP_LP_STAKING_CONTRACT_ADDR;
export const LP_TOKEN_ADDR = process.env.REACT_APP_LP_TOKEN_ADDR;
export const TREASURY_WALLET_ADDR = process.env.REACT_APP_TREASURY_WALLET_ADDR;
export const SUSHI_ROUTER_ADDRESS = process.env.REACT_APP_SUSHI_ROUTER_ADDRESS;
export const SUSHI_FACTORY_ADDRESS = process.env.REACT_APP_SUSHI_FACTORY_ADDRESS;
export const ETH_ADDRESS = process.env.REACT_APP_ETH_ADDRESS;
export const ARB_WETH_ADDRESS = process.env.REACT_APP_ARB_WETH_ADDRESS;
export const ETH_RPC_URL = process.env.REACT_APP_ETH_RPC_URL;
export const NETWORK_CHAIN_ID = parseInt(
  process.env.REACT_APP_NETWORK_CHAIN_ID
);
export const NETWORK_RPC_URL = process.env.REACT_APP_NETWORK_RPC_URL;
