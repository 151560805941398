import React from 'react';
import { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  Typography,
} from "@mui/material";

import { AppContext } from "../../utils";
import { useArbitrumContract, useNftContract } from "../../connectivity/Hooks";
import { formatUnits, parseEther, formatEther } from "@ethersproject/units";

import NftContainer from "../NftContainer/NftContainer";
import NftMintComplete from "./NftMintComplete";


import mintCompleteImg from "../../assets/moonbags-mint-complete.png";

function MoonbagHarvest () {
  const [userNfts, setUserNfts] = useState([]);

  const { account, signer } = useContext(AppContext);
  const nftContract = useNftContract(signer);

  const init = useCallback(async () => {
    const balanceOf = await nftContract.balanceOf(account);
    let tokenIds = [];
    for(let i = 0; i < Number(balanceOf); i++) {
      tokenIds.push(Number(await nftContract.tokenOfOwnerByIndex(account, i)));
    }
    setUserNfts(tokenIds);
  });

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div className="MoonbagHarvest">
      <Grid container mt={5} mx="auto" justifyContent="center">
        <NftMintComplete />
        {userNfts.map((tokenId, i) => {
          return (
            <NftContainer tokenId={tokenId} key={i} />
          );
        })}
      </Grid>
    </div>
  )
}

export default MoonbagHarvest;
