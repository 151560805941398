import React from 'react';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Stack,
  Button,
  Typography,
} from "@mui/material";

import { AppContext } from "../../utils";
import { useArbitrumContract, useNftContract } from "../../connectivity/Hooks";
import { formatUnits, parseEther, formatEther } from "@ethersproject/units";

import Loading from "../../loading";
import { ToastNotify } from "../../components/Alert";

function NftContainer (props) {
  const { tokenId } = props;
  const [nftMetadata, setNftMetadata] = useState();

  const [loading, setLoading] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const { account, signer } = useContext(AppContext);
  const nftContract = useNftContract(signer);

  const init = useCallback(async () => {
    try {
      if(nftContract) {
        const dataURI = await nftContract.tokenURI(tokenId);
        // 29 = length of "data:application/json;base64,"
        const json = atob(dataURI.substring(29));
        const metadata = JSON.parse(json);

        metadata.attributes = metadata.attributes.reduce((acc, attribute) => {
          acc[attribute.trait_type.replace(" ", "")] = attribute.value;
          return acc;
        }, {});

        console.log(metadata);

        setNftMetadata(metadata);
      }
    } catch (e) {
      if (e?.data?.message) {
        setAlertState({
          open: true,
          message: e?.data?.message,
          severity: "error",
        });
      } else if (e?.reason) {
        setAlertState({
          open: true,
          message: e?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: e?.message,
          severity: "error",
        });
      }
    }
  });

  useEffect(() => {
    init();
  }, [account]);

  const harvestHandler = useCallback(async () => {
    setLoading(true);

    try {
      let receipt = await nftContract.harvest(tokenId);
      await receipt.wait();

      init();
    } catch (e) {
      if (e?.data?.message) {
        setAlertState({
          open: true,
          message: e?.data?.message,
          severity: "error",
        });
      } else if (e?.reason) {
        setAlertState({
          open: true,
          message: e?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: e?.message,
          severity: "error",
        });
      }
    }
    setLoading(false);
  }, []);

  return (
    <Box
      className="shadow"
      sx={{
        background: "#000",
        borderRadius: "30px",
        px: 2.5,
        py: 3.5,
        transition: "0.5s",
        width: { xs: "95%", md: "20%" },
        m: "auto",
        margin: "15px"
      }}
    >
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      { nftMetadata && 
        <img src={nftMetadata.image} alt="" width="100%" />
      }
      { nftMetadata &&
        <Box pt={1} borderTop="1px solid #272128">
          <Stack
            mb={0.5}
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              variant="subtitle2"
              color="#d1d1d1"
              textAlign="left"
            >
              Available:
            </Typography>

            <Typography
              variant="subtitle2"
              color="#d1d1d1"
              textAlign="right"
            >
              { nftMetadata.attributes.Available.slice(0, -18) }
            </Typography>
          </Stack>

          <Stack
            mb={0.5}
            direction="row"
            justifyContent="space-between"
          >
            <Typography
              variant="subtitle2"
              color="#d1d1d1"
              textAlign="left"
            >
              Remaining:
            </Typography>

            <Typography
              variant="subtitle2"
              color="#d1d1d1"
              textAlign="right"
            >

              { nftMetadata.attributes.RewardRemaining.slice(0, -18) }
            </Typography>
          </Stack>
        </Box>
      }
      <Button
        onClick={() => harvestHandler()}
        sx={{
          width: "100%",
          paddingY: "5px",
          fontSize: "20px",
          color: "#000",
          backgroundColor: "#fff",
          border: "2px solid #5FBEEE",
          cursor: "pointer",
          textTransform: "capitalize",
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: "#fff",
            color: "#2584CF",
          },
        }}
      >
        Harvest
      </Button>
    </Box>

  );
}

export default NftContainer;
