import {
  Box,
  Container,
} from "@mui/material";

import BridgeForm from "../components/Bridge/BridgeForm";
import BridgeUserHistory from "../components/Bridge/BridgeUserHistory";
import TabbedContainer from "../components/TabbedContainer";

function Bridge() {
  return (
    <Container maxWidth="md">
      <Box textAlign="center" py={5}>
        <TabbedContainer
          Title="Bridge"
          TabPanelElements={[
            <BridgeForm />,
            //<BridgeUserHistory />
          ]}
          Labels={[
            //"Bride",
            //"HISTORY"
          ]}
        />
      </Box>
    </Container>
  );
}

export default Bridge;
