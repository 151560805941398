import { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { MaxUint256 } from "@ethersproject/constants";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { ethers } from "ethers";

import farming from "../assets/farming.png";
import { AppContext } from "../utils";
import { useFarmingContract, useLPTokenContract } from "../connectivity/Hooks";
import { Tooltip } from "react-tooltip";
import { LP_STAKING_CONTRACT_ADDR, NETWORK_CHAIN_ID } from "../connectivity/Environment";
import moment from "moment";
import Loading from "../loading";
import { ToastNotify } from "../components/Alert";

import AntTab from "../components/AntTab";
import TabPanel from "../components/TabPanel";
import FlexDataBox from "../components/FlexDataBox";

function Farming() {
  const [value, setValue] = useState(0);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [balanceOfLpValue, setBalanceOfLpValue] = useState(0);

  const { account, signer } = useContext(AppContext);
  const [userStakeData, setUserStakeData] = useState([]);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const [showValues, setShowValues] = useState({
    totalStaked: 0,
    totalReward: 0,
    totalWithdrawn: 0,
    currentStaked: 0,
  });

  const stakingLpContract = useFarmingContract(signer);
  const lpTokenContract = useLPTokenContract(signer);

  const init = useCallback(async () => {
    try {
      const decimals = await lpTokenContract.decimals();
      const totalStaked = await stakingLpContract.totalStaked();
      const distributedReward =
        await stakingLpContract.totalDistributedReward();
      const totalWithdrawn = await stakingLpContract.totalWithdrawn();

      if (account) {
        const balanceOf = await lpTokenContract.balanceOf(account);

        const { _stakeCount } = await stakingLpContract.getUserInfo(account);

        let dataArr = [];
        for (let i = 0; i < +_stakeCount; i++) {
          const {
            _lpAmount,
            _capturedFee,
            _startTime,
            _endTime,
            _reward,
            _harvestTime,
            _isWithdrawn,
          } = await stakingLpContract.getUserStakeInfo(account, i);

          let rewardTotal = await stakingLpContract.calculateReward(account, i);

          let obj = {
            stakedLp: +formatUnits(_lpAmount.toString(), +decimals),
            startTime: +_startTime,
            reward: parseFloat(
              +formatUnits(rewardTotal.toString(), +decimals)
            ).toFixed(0),
            rewardHarvested: parseFloat(
              +formatUnits(_reward.toString(), +decimals)
            ).toFixed(0),
            harvestTime: _harvestTime,
            _isWithdrawn,
          };
          dataArr.push(obj);
        }
        setUserStakeData(dataArr);
        setBalanceOfLpValue(+formatUnits(balanceOf.toString(), +decimals));
      }
      setShowValues({
        totalStaked: +formatUnits(totalStaked.toString(), +decimals),
        totalReward: parseFloat(
          +formatUnits(distributedReward.toString(), +decimals)
        ).toFixed(0),
        totalWithdrawn: +formatUnits(totalWithdrawn.toString(), +decimals),
        currentStaked: +formatUnits(
          totalStaked.sub(totalWithdrawn).toString(),
          +decimals
        ),
      });
    } catch (e) {
      if (e?.data?.message) {
        setAlertState({
          open: true,
          message: e?.data?.message,
          severity: "error",
        });
      } else if (e?.reason) {
        setAlertState({
          open: true,
          message: e?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: e?.message,
          severity: "error",
        });
      }
    }
  }, [account, lpTokenContract, stakingLpContract]);

  const stakeLpHandler = async () => {
    if (!account) {
      return setAlertState({
        open: true,
        message: "Please connect your wallet.",
        severity: "error",
      });
    }
    try {
      setLoading(true);

      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexValue(NETWORK_CHAIN_ID) }],
      });

      let allowance = await lpTokenContract.allowance(
        account,
        LP_STAKING_CONTRACT_ADDR
      );

      let amountToStake = parseUnits(amount.toString());
      let getAllowance = +formatUnits(allowance.toString());

      if (getAllowance < amountToStake) {
        let tx = await lpTokenContract.approve(
          LP_STAKING_CONTRACT_ADDR,
          MaxUint256.toString()
        );
        await tx.wait();
      }

      let res = await stakingLpContract.stake(amountToStake, {
        gasLimit: "2200000",
      });

      await res.wait();
      init();
      setAmount("");
      setLoading(false);
      setAlertState({
        open: true,
        message: "LP staked successfully!",
        severity: "success",
      });
    } catch (e) {
      setLoading(false);
      if (e?.data?.message) {
        setAlertState({
          open: true,
          message: e?.data?.message,
          severity: "error",
        });
      } else if (e?.reason) {
        setAlertState({
          open: true,
          message: e?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: e?.message,
          severity: "error",
        });
      }
    }
  };

  // withdraw handler
  const withdrawHandler = async (index) => {
    try {
      setLoading(true);
      let res = await stakingLpContract.withdraw(index, {
        gasLimit: "2200000",
      });
      await res.wait();
      init();
      setLoading(false);
      setAlertState({
        open: true,
        message: "LP withdrawn successfully!",
        severity: "success",
      });
    } catch (e) {
      setLoading(false);
      if (e?.data?.message) {
        setAlertState({
          open: true,
          message: e?.data?.message,
          severity: "error",
        });
      } else if (e?.reason) {
        setAlertState({
          open: true,
          message: e?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: e?.message,
          severity: "error",
        });
      }
    }
  };

  // harvest handler
  const harvestHandler = async (index) => {
    try {
      setLoading(true);
      let res = await stakingLpContract.harvest(index, {
        gasLimit: "2200000",
      });
      await res.wait();
      init();
      setLoading(false);
      setAlertState({
        open: true,
        message: "LP harvested successfully!",
        severity: "success",
      });
    } catch (e) {
      setLoading(false);
      if (e?.data?.message) {
        setAlertState({
          open: true,
          message: e?.data?.message,
          severity: "error",
        });
      } else if (e?.reason) {
        setAlertState({
          open: true,
          message: e?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: e?.message,
          severity: "error",
        });
      }
    }
  };

  useEffect(() => {
    init();
  }, [init]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  // input onChange handler
  const onChangeHandler = useCallback((e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (value.match(/^[0-9]*[.,]?[0-9]*$/)) {
      if (name === "base") {
        setAmount(value);
      }
    }
  }, []);

  return (
    <Container maxWidth="md">
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />

      <Box textAlign="center" py={5}>
        <Typography mb={5} variant="h2" color="#fff ">
          LP Farming
        </Typography>

        <Box
          sx={{
            background: "#2a2a2a",
            borderRadius: "30px",
            width: "fit-content",
            m: "auto",
            my: 4,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            <AntTab label="POOL" />
            <AntTab label="PERSONAL" />
          </Tabs>
        </Box>

        {/* ------------Tabs section---------- */}

        {/* ------------Pool Tab section---------- */}
        <TabPanel value={value} index={0}>
          <Box
            className="shadow"
            sx={{
              background: "#000",
              // background: "rgba(0, 0, 0, 0.4)",
              borderRadius: "30px",
              m: "auto",
              mt: 5,
              px: 2.5,
              py: 3.5,
              transition: "0.5s",
              width: { xs: "95%", md: "65%" },
            }}
          >
            <img src={farming} alt="" width="100%" />
            <Typography
              id="reward-pool-lp"
              variant="subtitle2"
              color="#d1d1d1"
              textAlign="left"
            >
              Reward Pool: 35% of Stake Tax
            </Typography>
            <Tooltip
              anchorId="reward-pool-lp"
              place="bottom"
              content=<small>
                LP Staking Rewards are calculated based on the amount of
                accumulated Arbinu from the 0.7% Stake Tax on each transaction.
              </small>
            />
            <FlexDataBox
              text="Currently staked"
              value={`${parseFloat(
                showValues.currentStaked
              ).toLocaleString()} LP`}
            />
            <FlexDataBox
              text="Rewards claimed"
              value={`${parseFloat(
                showValues.totalReward
              ).toLocaleString()} Arbinu`}
            />
            <FlexDataBox
              text="Total staked"
              value={`${parseFloat(
                showValues.totalStaked
              ).toLocaleString()} LP`}
              fontSize="14px"
            />
            <FlexDataBox
              text="Total withdrawn"
              value={`${parseFloat(
                showValues.totalWithdrawn
              ).toLocaleString()} LP`}
              fontSize="14px"
            />
            <Box pt={1} borderTop="1px solid #272128">
              <Stack mb={0.5} direction="row" justifyContent="space-between">
                <Typography
                  variant="subtitle2"
                  color="#d1d1d1"
                  textAlign="left"
                >
                  Balance:
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="#d1d1d1"
                  textAlign="right"
                >
                  {parseFloat(balanceOfLpValue).toFixed(2)}
                </Typography>
              </Stack>

              <TextField
                fullWidth
                size="small"
                placeholder="Enter amount to stake"
                name="base"
                value={amount}
                onChange={(e) => onChangeHandler(e)}
                // value={enterAmount}
                // onChange={(e) => setEnterAmount(e.target.value)}
                sx={{
                  border: "none",
                  background: "#41455A",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-root": {
                    border: "none",
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },

                  input: {
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "600",
                  },
                }}
              />
            </Box>

            <Button
              sx={{
                width: "100%",
                marginTop: "30px",
                color: "#000",
                paddingY: "5px",
                fontSize: "20px",
                backgroundColor: "#fff",
                border: "2px solid #5FBEEE",
                cursor: "pointer",
                textTransform: "capitalize",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#2584CF",
                },
              }}
              onClick={stakeLpHandler}
            >
              Stake LP
            </Button>
            <Button
              href="https://app.sushi.com/legacy/add/ETH/0xDd8e557C8804D326c72074e987de02A23ae6Ef84?chainId=42161"
              sx={{
                display: "inline-block",
                textDecoration: "none",
                color: "#5EBCEBa1",
                marginTop: "15px",
                fontSize: "14px",
                "&:hover": { color: "white" },
              }}
            >
              Get LP tokens by providing liquidity here.
            </Button>
          </Box>
        </TabPanel>

        {/* ------------Personal Tab section---------- */}
        <TabPanel value={value} index={1}>
          <Box mb={10} height="100%" width="100%">
            <TableContainer
              border="none"
              pt={2}
              pb={5}
              borderRadius="20px"
              color="#ffffff"
            >
              <Table
                aria-label="simple table"
                style={{
                  borderRadius: "16px",
                  marginTop: "50px",
                  background: "rgba(255, 255, 255, 0.2)",
                  minWidth: "600px",
                }}
                color="#ffffff"
              >
                <TableHead color="#ffffff">
                  <TableRow
                    style={{
                      color: "#ffffff",
                      border: "none",
                    }}
                  >
                    <TableCell
                      style={{
                        border: "none",
                        color: "#ffffff",
                      }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      //   align="center"
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "#ffffff",
                        border: "none",
                      }}
                    >
                      STAKED LP
                    </TableCell>
                    <TableCell
                      //   align="center"
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "#ffffff",
                        border: "none",
                      }}
                    >
                      REWARDS UNCLAIMED
                    </TableCell>
                    <TableCell
                      //   align="center"
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "#ffffff",
                        border: "none",
                      }}
                    >
                      REWARDS CLAIMED
                    </TableCell>
                    <TableCell
                      //   align="center"
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "#ffffff",
                        border: "none",
                      }}
                    >
                      START TIME
                    </TableCell>

                    <TableCell
                      colSpan={2}
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "#ffffff",
                        border: "none",
                        textAlign: "center",
                      }}
                    >
                      ACTIONS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userStakeData.length > 0 ? (
                    userStakeData.map(
                      (
                        {
                          stakedLp,
                          startTime,
                          reward,
                          rewardHarvested,
                          _isWithdrawn,
                          harvestTime,
                        },
                        i
                      ) => (
                        <TableRow key={i}>
                          <TableCell
                            // align="center"
                            style={{
                              border: "none",
                              color: "#ffffff",
                            }}
                          >
                            {i + 1}
                          </TableCell>
                          <TableCell
                            // align="center"
                            style={{
                              border: "none",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#ffffff",
                              }}
                            >
                              {parseFloat(stakedLp).toLocaleString()}
                            </Typography>
                          </TableCell>
                          <TableCell
                            // align="center"
                            style={{
                              border: "none",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#ffffff",
                              }}
                            >
                              {parseFloat(reward).toLocaleString()}
                            </Typography>
                          </TableCell>
                          <TableCell
                            // align="center"
                            style={{
                              border: "none",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#ffffff",
                              }}
                            >
                              {parseFloat(rewardHarvested).toLocaleString()}
                            </Typography>
                          </TableCell>
                          <TableCell
                            // align="center"
                            style={{
                              border: "none",
                              textAlign: "left",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#ffffff",
                              }}
                            >
                              {moment.unix(startTime).format("lll")}
                            </Typography>
                          </TableCell>
                          {!_isWithdrawn ? (
                            <>
                              <TableCell
                                // align="center"
                                style={{
                                  border: "none",
                                  textAlign: "right",
                                }}
                              >
                                <Button
                                  disabled={_isWithdrawn ? true : false}
                                  sx={{
                                    width: "80%",
                                    color: "#000",
                                    backgroundColor: "#fff",
                                    border: "2px solid #5FBEEE",
                                    cursor: "pointer",
                                    textTransform: "capitalize",
                                    borderRadius: "16px",
                                    "&:hover": {
                                      backgroundColor: "#5EBCEBa1",
                                      color: "#fff",
                                    },
                                  }}
                                  onClick={() => withdrawHandler(i)}
                                >
                                  <span style={{ padding: "1px 8px" }}>
                                    withdraw
                                  </span>
                                </Button>
                              </TableCell>
                              <TableCell
                                // align="center"
                                style={{
                                  border: "none",
                                  textAlign: "left",
                                }}
                              >
                                <Button
                                  disabled={
                                    _isWithdrawn ||
                                    parseInt(harvestTime) + 86400 >
                                      moment().unix()
                                  }
                                  sx={{
                                    width: "80%",
                                    color: "#fff",
                                    background: "#5EBCEB",
                                    cursor: "pointer",
                                    border: "2px solid #5FBEEE",
                                    textTransform: "capitalize",
                                    borderRadius: "16px",
                                    "&:hover": {
                                      background: "#5EBCEBa1",
                                      color: "#fff",
                                    },
                                  }}
                                  onClick={() => harvestHandler(i)}
                                >
                                  <span style={{ padding: "1px 8px" }}>
                                    harvest
                                  </span>
                                </Button>
                              </TableCell>
                            </>
                          ) : (
                            <TableCell
                              colSpan={2}
                              style={{
                                border: "none",
                                textAlign: "center",
                                color: "white",
                              }}
                            >
                              <span>WITHDRAWN</span>
                            </TableCell>
                          )}
                        </TableRow>
                      )
                    )
                  ) : (
                    <TableRow>
                      <TableCell
                        // align="center"
                        colSpan={6}
                        style={{
                          border: "none",
                        }}
                      >
                        <Box
                          py={10}
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontSize: "17px",
                              fontWeight: "400",
                              marginTop: "10px",
                              color: "#ffffff",
                            }}
                          >
                            You have not staked any LP yet.
                            <br />
                            <Button
                              href="https://app.sushi.com/legacy/add/ETH/0xDd8e557C8804D326c72074e987de02A23ae6Ef84?chainId=42161"
                              sx={{
                                display: "inline-block",
                                textDecoration: "none",
                                color: "#5EBCEBa1",
                                marginTop: "15px",
                                fontSize: "14px",
                                "&:hover": { color: "white" },
                              }}
                            >
                              Get LP tokens by providing liquidity here.
                            </Button>
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </TabPanel>
      </Box>
    </Container>
  );
}

export default Farming;
