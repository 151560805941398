import React from 'react';
import { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  Typography,
} from "@mui/material";

import { AppContext } from "../../utils";
import { formatUnits, parseEther, formatEther } from "@ethersproject/units";


import mintCompleteImg from "../../assets/moonbags-mint-complete.png";


function NftMintComplete () {
  const openseaLinkHandler = useCallback((e) => {
    window.open('https://opensea.io/collection/moonbagnft', '_blank')
  }, []);

  return (
    <Box
      className="shadow"
      sx={{
        background: "#000",
        borderRadius: "30px",
        px: 2.5,
        py: 3.5,
        transition: "0.5s",
        width: { xs: "95%", md: "50%" },
        m: "auto"
      }}
    >
      <img src={mintCompleteImg} alt="" width="100%" />
      <Button
        onClick={() => openseaLinkHandler()}
        sx={{
          width: "100%",
          paddingY: "5px",
          fontSize: "20px",
          color: "#000",
          backgroundColor: "#fff",
          border: "2px solid #5FBEEE",
          cursor: "pointer",
          textTransform: "capitalize",
          borderRadius: "10px",
          marginTop: "30px",
          "&:hover": {
            backgroundColor: "#fff",
            color: "#2584CF",
          },
        }}
      >
        Buy on OpenSea
      </Button>

    </Box>


  )
}

export default NftMintComplete;
