import { createTheme } from "@mui/material";

// import bgImg2 from "./assets/bgImg.png";
import bg from "./assets/bg.png";

export const theme = createTheme({
  // palette: {
  //   mode: 'dark',
  // },
  typography: {
    fontFamily: "Poppins",
    subtitle1: {
      fontWeight: "400",
      fontSize: "25px",
      lineHeight: "30.48px",
      marginBottom: "8px",
    },
    h5: {
      fontWeight: "700",
      fontSize: "25px",
      lineHeight: "30.48px",
    },
    h6: {
      fontWeight: "400",
      fontSize: "22px",
      lineHeight: "24.38px",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: `url(${bg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundBlendMode: "darken",
          minHeight: "100vh",
        },
      },
    },
  },
});
