import React from 'react';
import { useState } from "react";
import {
  Box,
  Tabs,
  Typography,
} from "@mui/material";

import AntTab from "./AntTab";
import TabPanel from "./TabPanel";

function TabbedContainer(props) {
  const { TabPanelElements, Title, Labels } = props;
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  console.log(TabPanelElements);

  return(
    <div className="TabbedContainer">
      <Typography mb={5} variant="h2" color="#fff">
        { Title }
      </Typography>

      {Labels.length > 0 &&
          <Box
            sx={{
              background: "#2a2a2a",
              borderRadius: "30px",
              width: "fit-content",
              m: "auto",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              {Labels.map((label, i) => {
                return (
                  <AntTab label={label} index={i} key={i}/>
                );
              })}
            </Tabs>
          </Box>
      }
      {TabPanelElements.map((element, i) => {
        return (
          <TabPanel value={value} index={i} key={i}>
            {element}
          </TabPanel>
        );
      })}
    </div>
  )
}

export default TabbedContainer;
