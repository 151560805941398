import React from "react";
import { Dialog, DialogContent, Box, Slide, Button } from "@mui/material";
import { utils } from "ethers";
import { NETWORK_CHAIN_ID } from "./connectivity/Environment";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function NetworkChange({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };
  const networkHandler = async () => {
    try {
      const networkChainId = utils.hexValue(NETWORK_CHAIN_ID);
      console.log(networkChainId);
      setOpen(false);
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [{
          chainId: networkChainId,
          rpcUrls: ["https://arbitrum-one.public.blastapi.io/"],
          chainName: "Arbitrum One",
          nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18
          },
          blockExplorerUrls: ["https://arbiscan.io/"]
        }]
      });
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: utils.hexValue(NETWORK_CHAIN_ID) }],
      });
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div className="modal__main__container">
        <Dialog
          open={open}
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          sx={{
            zIndex: 10,
            "& .MuiPaper-root": {
              borderRadius: "30px",
              border: "1.5px solid #5ebceb",
            },
          }}
        >
          <DialogContent
            sx={{
              backgroundColor: "#000",
            }}
            className="dialoge__content__section"
          >
            <Box component="h3" color="#fff">
              <Box component="span" color="red" fontSize="30px">
                Error!
              </Box>{" "}
              You are on the wrong network, please switch your network.
            </Box>
            <Box align="center">
              <Button
                sx={{
                  width: "180px",
                  height: "42px",
                  fontWeight: "700",
                  background: "#5EBCEB",
                  color: "#fff",
                  "&:hover": {
                    background: "#5EBCEBa1",
                  },
                }}
                onClick={networkHandler}
              >
                Switch Network
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

export default NetworkChange;
