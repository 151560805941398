import React from 'react';
import {
  Stack,
  Typography,
} from "@mui/material";

export const FlexDataBox = ({ text, value, fontSize = "18px" }) => {
  return (
    <div className="FlexDataBox">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={2}
      >
        <Typography
          variant="body1"
          sx={{
            color: "#fff",
            fontSize: "20px",
          }}
        >
          {text}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#fff",
            fontSize: "20px",
          }}
        >
          {value}
        </Typography>
      </Stack>
    </div>
  );
};

export default FlexDataBox;
