import React from "react";
import { ethers, Contract } from "ethers";
// import {  } from "@ethersproject/contracts";

import stakingAbi from "./stakingAbi.json";
import tokenAbi from "./tokenAbi.json";
import farmingAbi from "./farmingAbi.json";
import lpTokenAbi from "./lpTokenAbi.json";
import arbinuNftAbi from "./arbinuNftAbi.json";
import UniswapV2Router02 from "./UniswapV2Router02.json";
import {
  STAKING_CONTRACT_ADDR,
  ARBINU_TOKEN_ADDR,
  ARBINU_NFT_ADDR,
  ARBITRUM_ADDR,
  LP_STAKING_CONTRACT_ADDR,
  LP_TOKEN_ADDR,
  TREASURY_WALLET_ADDR,
  NETWORK_RPC_URL,
  SUSHI_ROUTER_ADDRESS
} from "./Environment";

const provider = new ethers.providers.JsonRpcProvider(NETWORK_RPC_URL);

export const voidAccount = new ethers.VoidSigner(
  "0x0000000000000000000000000000000000000000",
  provider
);
function useContract(address, ABI, signer) {
  return React.useMemo(() => {
    if (signer) {
      return new Contract(address, ABI, signer);
    } else {
      return new Contract(address, ABI, voidAccount);
    }
  }, [address, ABI, signer]);
}

export function useStakingContract(signer) {
  return useContract(STAKING_CONTRACT_ADDR, stakingAbi, signer);
}
export function useTokenContract(signer) {
  return useContract(ARBINU_TOKEN_ADDR, tokenAbi, signer);
}
export function useFarmingContract(signer) {
  return useContract(LP_STAKING_CONTRACT_ADDR, farmingAbi, signer);
}
export function useLPTokenContract(signer) {
  return useContract(LP_TOKEN_ADDR, lpTokenAbi, signer);
}
export function useNftContract(signer) {
  return useContract(ARBINU_NFT_ADDR, arbinuNftAbi, signer);
}
export function useArbitrumContract(signer) {
  return useContract(ARBITRUM_ADDR, tokenAbi, signer);
}
export function useRouterContract(signer) {
  return useContract(SUSHI_ROUTER_ADDRESS, UniswapV2Router02, signer);
}
