import React from 'react';
import {
  styled,
  Tab,
} from "@mui/material";

const AntTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  textTransform: "none",
  fontSize: "14px",
  borderRadius: "30px",
  color: "#fff",
  "&.Mui-selected": {
    background: "#59C7FB",
    color: "#fff",
  },
}));

export default AntTab;
