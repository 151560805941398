import { useEffect, useCallback } from "react";
import {
  Box,
  Container,
} from "@mui/material";

import NftMintForm from "../components/NftMint/NftMintForm";
import NftMintComplete from "../components/NftMint/NftMintComplete";
import MoonbagHarvest from "../components/NftMint/MoonbagHarvest";
import TabbedContainer from "../components/TabbedContainer";

import { ethers } from "ethers";
import { NETWORK_CHAIN_ID } from "../connectivity/Environment";

function NftMint() {
  const init = useCallback(async () => {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: ethers.utils.hexValue(NETWORK_CHAIN_ID) }],
    });
  });
  useEffect(() => {
    //document.body.style.backgroundImage = 'url("https://cdn.discordapp.com/attachments/810378230866640929/1087851901376270488/moonbag-cards-bg.gif")'
    //document.body.style.backgroundImage = 'url("https://cdn.discordapp.com/attachments/810378230866640929/1087861508865798194/More_Cards.png")'
    document.body.style.backgroundImage = 'url("https://cdn.discordapp.com/attachments/810378230866640929/1088850864044769430/IMG_0572.png")'

    return () => {
      document.body.style.backgroundImage = "";
    };
  }, []);


  return (
    <Container maxWidth="md">
      <Box textAlign="center" py={5}>
        <TabbedContainer
          Title="Moonbags"
          TabPanelElements={[
            //<NftMintForm />,
            //<NftMintComplete />,
            <MoonbagHarvest />,
          ]}
          Labels={[
            //"Buy",
            //"Harvest"
          ]}
        />
      </Box>
    </Container>
  );
}

export default NftMint;
