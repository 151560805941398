import React from 'react';
import { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
} from "@mui/material";

import { Squid } from "@0xsquid/sdk";
import { ethers } from "ethers";

import { AppContext } from "../../utils";
import { useTokenContract, useRouterContract } from "../../connectivity/Hooks";
import { formatUnits } from "@ethersproject/units";
import { ARBINU_TOKEN_ADDR,
  SUSHI_ROUTER_ADDRESS,
  SUSHI_FACTORY_ADDRESS,
  ETH_ADDRESS,
  ARB_WETH_ADDRESS,
  ETH_RPC_URL,
  NETWORK_RPC_URL
} from "../../connectivity/Environment";

import bridgeImg from "../../assets/ArbinuBridge.png";
import Loading from "../../loading";
import { ToastNotify } from "../../components/Alert";
import tokenAbi from "../../connectivity/tokenAbi.json";
import UniswapV2Router02 from "../../connectivity/UniswapV2Router02.json";
import UniswapV2Factory from "../../connectivity/UniswapV2Factory.json";
import UniswapV2Pair from "../../connectivity/UniwapV2Pair.json";

const factoryContract = new ethers.Contract(SUSHI_FACTORY_ADDRESS, UniswapV2Factory);

const arbitrumId = 42161;

async function getTokenPriceInETH() {
  try {
  const pairAddress = await factoryContract.getPair(ARBINU_TOKEN_ADDR, ETH_ADDRESS);

  const pairContract = new ethers.Contract(pairAddress, UniswapV2Pair);

  const reserves = await pairContract.getReserves();
  const tokenReserve = reserves._reserve0;
  const ethReserve = reserves._reserve1;

  const tokenPriceInETH = ethReserve / tokenReserve;

  return tokenPriceInETH;
  } catch(e) {
    console.log(e);
  }
}

function BridgeForm () {
  const [inputValue, setInputValue] = useState(0);
  const [outputValue, setOutputValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [balanceOfValue, setBalanceOfValue] = useState(0);
  const [balanceValue, setBalanceValue] = useState(0);
  const [fromNetwork, setFromNetwork] = useState(1);
  const [minArbinuAmountOut, setMinArbinuAmountOut] = useState();
  const [receiveGas, setReceiveGas] = useState(false);
  const [minEthAmountOut, setMinEthAmountOut] = useState();
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const { account, signer } = useContext(AppContext);
  const tokenContract = useTokenContract();
  const routerContract = useRouterContract();

  const rpcUrls = {
    "1": ETH_RPC_URL,
    "56": "https://bsc-dataseed3.binance.org",
  }

  const init = useCallback(async () => {
    try {
      if (account) {
        try {
          const balanceOf = await tokenContract.balanceOf(account);
          setBalanceOfValue(ethers.utils.formatEther(balanceOf.toString()));
        } catch (e) {
          console.log(e);
        }

        const ethProvider = new ethers.providers.JsonRpcProvider(rpcUrls[fromNetwork]);
        const balance = await ethProvider.getBalance(account);
        const balanceInEth = ethers.utils.formatEther(balance);
        setBalanceValue(balanceInEth);
      }
    } catch (e) {
      if (e?.data?.message) {
        setAlertState({
          open: true,
          message: e?.data?.message,
          severity: "error",
        });
      } else if (e?.reason) {
        setAlertState({
          open: true,
          message: e?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: e?.message,
          severity: "error",
        });
      }
    }
  }, [account, tokenContract, fromNetwork]);

  useEffect(() => {
    init();
  }, [init, fromNetwork]);

  const getSDK = (): Squid => {
    const squid = new Squid({
      baseUrl: "https://api.0xsquid.com"
    });
    return squid;
  };

  const bridgeHandler = async () => {
    try {
      setLoading(true);

      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexValue(fromNetwork) }],
      });

      const squid = getSDK();
      await squid.init();

      const amountIn = ethers.utils.parseEther(inputValue);

      console.log("chains: ", squid.chains);
      console.log("tokens: ", squid.tokens.find(t => t.chainId === arbitrumId));
      console.log("tokens: ", squid.tokens);

      const erc20ContractInterface = new ethers.utils.Interface(tokenAbi);
      const approveEncodeData = erc20ContractInterface.encodeFunctionData(
        "approve",
        [
          SUSHI_ROUTER_ADDRESS,
          ethers.utils.parseEther("10000000000000000000000000000000000")
        ]
      );

      const routerContractInterface = new ethers.utils.Interface(UniswapV2Router02);

      const tokenPriceInETH = await getTokenPriceInETH();
      const slippageTolerance = 6; // 6%
      const minAmountOut = outputValue - (outputValue * (slippageTolerance / 100))

      const swapEncodeData = routerContractInterface.encodeFunctionData(
        "swapExactETHForTokensSupportingFeeOnTransferTokens",
        [
          ethers.utils.parseEther(minAmountOut.toString()),
          [ARB_WETH_ADDRESS, ARBINU_TOKEN_ADDR],
          account,
          new Date().getTime() + 1e6
        ]
      );

      console.log("account: ", account);
      const params = {
        fromChain: fromNetwork,
        fromToken: ETH_ADDRESS,
        fromAmount: ethers.utils.parseEther(inputValue).toString(),
        toChain: arbitrumId,
        toToken: ETH_ADDRESS,
        toAddress: account,
        slippage: 6, // 6.00 = 6% max slippage across the entire route
        enableForecall: true, // instant execution service, defaults to true
        quoteOnly: false, // optional, defaults to false
        //receiveGasOnDestination: true,
        customContractCalls: [
          {
            callType: 2,
            target: SUSHI_ROUTER_ADDRESS,
            value: ethers.utils.parseEther(minEthAmountOut).toString(),
            callData: swapEncodeData,
            payload: {
              tokenAddress: ETH_ADDRESS,
              inputPos: 0
            },
            estimatedGas: "400000"
          }
        ]
      };

      const { route } = await squid.getRoute(params);
      const tx = await squid.executeRoute({ signer, route });
      const txReceipt = await tx.wait();
      const getStatusParams = {
        transactionId: txReceipt.transactionHash,
        routeType: route.transactionRequest.routeType
      };

      const status = await squid.getStatus(getStatusParams);

      init();
      setLoading(false);
      setAlertState({
        open: true,
        message: "Bridged Successfully!!!\ntx: " + status.axelarTransactionUrl,
        severity: "success",
      });
    } catch (e) {
      setLoading(false);
      console.log(e);
      if (e?.data?.message) {
        setAlertState({
          open: true,
          message: e?.data?.message,
          severity: "error",
        });
      } else if (e?.reason) {
        setAlertState({
          open: true,
          message: e?.reason,
          severity: "error",
        });
      } else {
        setAlertState({
          open: true,
          message: e?.message,
          severity: "error",
        });
      }
    }
  };

  // input onChange handler
  const onChangeHandler = useCallback(async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (value.match(/^[0-9]*[.,]?[0-9]*$/)) {
      if (name === "input") {
        setInputValue(value);
        setOutputValue("Loading...");
        setMinEthAmountOut("Loading...");
        setMinArbinuAmountOut("Loading...");
        setLoadingData(true);

        if(value > 0) {
          const squid = getSDK();
          await squid.init();

          const amountIn = ethers.utils.parseEther(value);

          console.log("chains: ", squid.chains);
          console.log("arb tokens: ", squid.tokens.find(t => t.chainId === arbitrumId));
          console.log("all tokens: ", squid.tokens);

          const params = {
            fromChain: fromNetwork,
            fromToken: ETH_ADDRESS,
            fromAmount: ethers.utils.parseEther(value).toString(),
            toChain: arbitrumId,
            toToken: ETH_ADDRESS,
            toAddress: account,
            slippage: 6, // 6.00 = 6% max slippage across the entire route
            enableForecall: true, // instant execution service, defaults to true
            quoteOnly: false, // optional, defaults to false
          };

          console.log("params: \n", params);

          const { route } = await squid.getRoute(params);
          console.log("route: \n", route);

          let amountsOut = await routerContract.getAmountsOut(route.estimate.toAmountMin, [ARB_WETH_ADDRESS, ARBINU_TOKEN_ADDR]);

          setMinEthAmountOut(ethers.utils.formatEther(route.estimate.toAmountMin));
          setMinArbinuAmountOut(ethers.utils.formatEther(amountsOut[1]));
          setOutputValue(ethers.utils.formatEther(amountsOut[1]));
        } else {
          setMinEthAmountOut(0);
          setMinArbinuAmountOut(0);
          setOutputValue(0);
        }

      }
      if (name === "output") {
        setOutputValue(value);
        let amountsIn = await routerContract.getAmountsIn(ethers.utils.parseEther(value), [ARB_WETH_ADDRESS, ARBINU_TOKEN_ADDR]);
        setInputValue(ethers.utils.formatEther(amountsIn[0]));
      }
      setLoadingData(false);
    }
  }, [outputValue, inputValue]);

  const onChangeNetworkHandler = useCallback(async (e) => {
    const network = e.target.value;
    setBalanceValue("loading...");
    setOutputValue("Loading...");
    setMinEthAmountOut("Loading...");
    setMinArbinuAmountOut("Loading...");
    setLoadingData(true);

    console.log("network id: ", network);
    console.log("network id hexValue: ", ethers.utils.hexValue(network));
    setFromNetwork(network);
    if (inputValue > 0) {
      const squid = getSDK();
      await squid.init();
      const amountIn = ethers.utils.parseEther(inputValue);
      const params = {
        fromChain: network,
        fromToken: ETH_ADDRESS,
        fromAmount: ethers.utils.parseEther(inputValue).toString(),
        toChain: arbitrumId,
        toToken: ETH_ADDRESS,
        toAddress: account,
        slippage: 6, // 6.00 = 6% max slippage across the entire route
        enableForecall: true, // instant execution service, defaults to true
        quoteOnly: false, // optional, defaults to false
      };
      const { route } = await squid.getRoute(params);
      let amountsOut = await routerContract.getAmountsOut(route.estimate.toAmountMin, [ARB_WETH_ADDRESS, ARBINU_TOKEN_ADDR]);
      setMinEthAmountOut(ethers.utils.formatEther(route.estimate.toAmountMin));
      setMinArbinuAmountOut(ethers.utils.formatEther(amountsOut[1]));
      setOutputValue(ethers.utils.formatEther(amountsOut[1]));
    } else {
      setMinEthAmountOut(0);
      setMinArbinuAmountOut(0);
      setOutputValue(0);
    }
    setLoadingData(false);
  }, [fromNetwork, balanceValue, outputValue, minEthAmountOut, minArbinuAmountOut]);

  const receiveGasHandler = useCallback(async (e) => {
    setReceiveGas(e.value);
  });

  return (
    <div className="BridgeForm">
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />

      <Grid container mt={5} mx="auto">
        <Box
          className="shadow"
          sx={{
            background: "#000",
            borderRadius: "30px",
            px: 2.5,
            py: 3.5,
            transition: "0.5s",
            width: { xs: "95%", md: "65%" },
            m: "auto"
          }}
        >
          <img src={bridgeImg} alt="" width="100%" />

          <Box pt={1} borderTop="1px solid #272128">
            <FormControl fullWidth>
              <Select
                fullWidth
                size="small"
                name="network-input-select"
                id="network-input-select"
                value={fromNetwork}
                onChange={(e) => onChangeNetworkHandler(e)}
                sx={{
                  border: "none",
                  background: "#41455A",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-root": {
                    border: "none",
                    "& fieldset": {
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },

                  select: {
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "600",
                  },
                }}
              >
                <MenuItem value={1}>Ethereum</MenuItem>
                <MenuItem value={56}>BSC</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/*
          <Box pt={1} borderTop="1px solid #272128">
            <Stack
              direction="row"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle2"
                color="#d1d1d1"
                textAlign="right"
              >
                Receive Gas:
                <Switch
                  label="Receive Gas"
                  value={receiveGas}
                  onChange={(e) => receiveGasHandler(e)}
                />
              </Typography>
            </Stack>
          </Box>
          */}

          <Box pt={1} borderTop="1px solid #272128">
            <Stack
              mb={0.5}
              direction="row"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle2"
                color="#d1d1d1"
                textAlign="left"
              >
                { fromNetwork == 1 &&
                  <>ETH:</>
                }
                { fromNetwork == 56 &&
                  <>BNB:</>
                }
              </Typography>
              <Typography
                variant="subtitle2"
                color="#d1d1d1"
                textAlign="right"
              >
                {parseFloat(balanceValue) ? parseFloat(balanceValue).toFixed(3) : balanceValue}
              </Typography>
            </Stack>

            <TextField
              fullWidth
              size="small"
              name="input"
              value={inputValue}
              onChange={(e) => onChangeHandler(e)}
              placeholder="Enter amount to bridge & swap to arbinu"
              sx={{
                border: "none",
                background: "#41455A",
                borderRadius: "5px",
                "& .MuiOutlinedInput-root": {
                  border: "none",
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },

                input: {
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "600",
                },
              }}
            />
          </Box>

          <Box pt={1} borderTop="1px solid #272128">
            <Stack
              mb={0.5}
              direction="row"
              justifyContent="space-between"
            >
              <Typography
                variant="subtitle2"
                color="#d1d1d1"
                textAlign="left"
              >
                ArbInu:
              </Typography>
            </Stack>

            <TextField
              fullWidth
              size="small"
              name="output"
              value={outputValue}
              disabled={true}
              onChange={(e) => onChangeHandler(e)}
              placeholder="Enter amount to bridge & swap to arbinu"
              sx={{
                border: "none",
                background: "#41455A",
                borderRadius: "5px",
                "& .MuiOutlinedInput-root": {
                  border: "none",
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "none",
                  },
                  "&.Mui-focused fieldset": {
                    border: "none",
                  },
                },

                input: {
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "600",
                },
              }}
            />
          </Box>

          <Button
            onClick={() => bridgeHandler()}
            disabled={loadingData}
            sx={{
              width: "100%",
              paddingY: "5px",
              fontSize: "20px",
              color: "#000",
              backgroundColor: "#fff",
              border: "2px solid #5FBEEE",
              cursor: "pointer",
              textTransform: "capitalize",
              borderRadius: "10px",
              marginTop: "30px",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#2584CF",
              },
            }}
          >
            { !loadingData &&
              <>Bridge</>
            }
            { loadingData &&
              <>Loading...</>
            }
          </Button>
        </Box>
      </Grid>
    </div>
  )
}

export default BridgeForm;
